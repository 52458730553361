import { useRouter } from 'next/router'
import { MutableRefObject, useEffect, useState } from 'react'

interface UseHashStateProps {
  ref?: MutableRefObject<HTMLElement | null>
  initialSection?: string
}
export const useHashState = ({ ref, initialSection }: UseHashStateProps) => {
  const router = useRouter()
  const [activeSection, setActiveSection] = useState(initialSection)

  useEffect(() => {
    const onHashChangeComplete = (location) => {
      const hash = location.split('#')[1]
      if (hash && hash !== activeSection) {
        setActiveSection(hash)
        const target = document.getElementById(hash)
        if (target) {
          const scrollOffset = ref?.current?.getBoundingClientRect().height || 0
          target.style.scrollMargin = `${scrollOffset}px`
          target.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }

    router?.events.on('hashChangeComplete', onHashChangeComplete)

    return () => router?.events.off('hashChangeComplete', onHashChangeComplete)
  }, [router?.events, activeSection, ref])

  return activeSection
}
